import { useState } from "react"

type LogoProps = {
    size?: string,
    color?: 'branca' | 'colorida',
    tipo?: 'quebrado' | 'normal' | 'simbolo'
}

export default function Logomarca(props: LogoProps) {
    const { size, color, tipo } = props
    const [sizeProps, setSize] = useState(props.size ? props.size : '300px')
    let logoColor: string = ''
    if (color == 'branca') {
        if (tipo == null || tipo == 'normal') {
            logoColor = '/images/logomarca-industria-branca.png'
        } else if (tipo == 'simbolo') {
            logoColor = '/images/simbolo-industria.png'
        } else {
            logoColor = '/images/logo-quebrado-branca.png'
        }
    } else {
        if (tipo == null || tipo == 'normal') {
            logoColor = '/images/logomarca-industria.png'
        } else if (tipo == 'simbolo') {
            logoColor = '/images/simbolo-industria.png'
        }else {
            logoColor = '/images/logo-quebrado.png'
        }
    }

    const [logo, setLogo] = useState(logoColor)

    return (
        <>
            <img className="logocss" src={logo} alt="" />

            <style jsx>{`
                .logocss{
                    width: ${sizeProps}
                }
            `}</style>
        </>
    )
}