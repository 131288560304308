import { ArchiveBoxIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { FaFacebook, FaInstagram, FaMailBulk, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { RiEarthFill, RiSecurePaymentFill, RiShoppingCartFill } from "react-icons/ri";
import Logomarca from "./Logomarca";
import { useRouter } from "next/router";

interface props {
    marginBotton?: number
}

export default function FooterHome({marginBotton}: props) {
    const router = useRouter()

    const redeSocialItem = (icone: any, link: string) => {

        const abreLink = () => {
            if (link != '') {
                window.open(link, '_blank')
            }
        }

        return (
            <>
                <div className="rounded-md bg-gray-900 p-2 cursor-pointer" onClick={() => abreLink()}>
                    {icone}
                </div>
            </>
        )
    }

    return (
        <>
            <div style={{marginBottom: marginBotton ? marginBotton : 0}}>
                <div className="bg-gray-800 p-3 flex justify-center gap-7 text-white">
                    {redeSocialItem(<FaInstagram size={28} />, 'https://instagram.com/industriadoingresso')}
                    {redeSocialItem(<FaFacebook size={28} />, '')}
                    {redeSocialItem(<FaYoutube size={28} />, '')}
                    {redeSocialItem(<FaTwitter size={28} />, '')}
                </div>
                <div className=" w-full flex justify-center md:px-10 py-20 bg-gray-900" style={{ fontSize: "12px" }}>
                    <div className="container px-5 w-full grid lg:grid-cols-4 md:grid-cols-2 text-white">
                        <div className="grid">
                            <div className="text-center">
                                <div className="m-auto " style={{ width: '70%', maxWidth: '300px' }}>
                                    <Logomarca tipo="quebrado" color="branca" size="100%" />
                                </div>
                            </div>
                            <div className="w-full flex justify-center items-start mt-14 md:mt-0">
                                <img src="/images/siteseguro.svg" style={{ width: '200px' }} />
                            </div>
                        </div>
                        <div className="text-center sm:mt-20 md:mt-0 mt-10 h-32 md:border-l-2 border-gray-700 px-5" style={{ height: "200px" }}>
                            <div className="lg:text-lg text-md mb-7 md:mb-0 ">
                                <strong>MÉTODOS DE PAGAMENTO</strong>
                            </div>
                            <div className="px-5 ">
                                <p className="mb-3">Pagamentos em até 10 vezes no cartão</p>
                                <img className="m-auto" src="/images/metodos_pagamento.png" alt="" />
                            </div>
                            <div className="flex gap-4 mt-10 items-center">
                                <img className="bandeira-rodape" src="/images/informacao.png" alt="" />
                                <p>Todos os preços e condições comerciais estão sujeitos a alteração comercial sem prévio</p>
                            </div>
                        </div>
                        <div className="lg:mt-0 sm:mt-20 mt-10 pl-16 lg:border-l-2 border-gray-700">
                            <div className="lg:text-lg grid grid-col-1 text-left">
                                <strong>POLÍTICAS</strong>
                            </div>
                            <div className="flex justify-left mt-5">
                                <div className="gap-2 flex flex-col font-bold">
                                    <p className="cursor-pointer hover:underline" onClick={() => { window.open('/auth/politica', '_blank') }}>Privacidade</p>
                                    <p className="cursor-pointer hover:underline" onClick={() => { window.open('/auth/regras-meia-entrada', '_blank') }}>Meia Entrada</p>
                                    <p className="cursor-pointer hover:underline" onClick={() => { window.open('/auth/termos', '_blank') }}>Termos de Uso</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:mt-0 sm:mt-20 mt-10 text-left pl-16 md:border-l-2 border-gray-700">
                            <div className="lg:text-lg grid grid-col-1 ">
                                <strong>MINHA CONTA</strong>
                            </div>
                            <div className="flex justify-left mt-5">
                                <div className="gap-2 flex flex-col font-bold">
                                    <p className="cursor-pointer hover:underline" onClick={() => { window.open('/dashboard/pedidos', '_self') }}>Meus Ingressos</p>
                                    <p className="cursor-pointer hover:underline" onClick={() => { window.open('/dashboard/perfil', '_self') }}>Meu Perfil</p>
                                    <p className="cursor-pointer hover:underline" onClick={() => { window.open('/dashboard/perfil/senha', '_self') }}>Alterar Senha</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="text-center grid text-gray-900 p-4 container m-auto justify-center items-center " style={{ fontSize: '11px' }}>
                    <div>© <strong>Indústria do Ingresso</strong> {new Date().getFullYear()} - Todos os direitos reservados - www.industriadoingresso.com.br</div>
                </div>
            </div>
        </>
    )
}