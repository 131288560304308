import { Fragment, FC } from "react"
import { Badge, Button } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { ImPriceTags } from 'react-icons/im';
import { GrClose, GrDashboard, GrLogin, GrUserAdmin } from 'react-icons/gr'
import { RiAdminFill, RiCoupon3Fill, RiDashboard3Line, RiLoginCircleLine, RiMenu2Line } from 'react-icons/ri'
import { useRouter } from 'next/router'
import { HiCheck, HiLogin } from 'react-icons/hi';
import utils from '@/shared/utils';
import { useQtdCart } from '@/shared/useQtdCart';
import { ChevronDownIcon, Cog8ToothIcon, CogIcon, UserIcon } from '@heroicons/react/24/solid';
import { Menu, Transition, Popover } from "@headlessui/react"
import { signOut, useSession } from "next-auth/react";
import { FaShoppingCart } from "react-icons/fa";
import { AiOutlineLogin } from "react-icons/ai";
import { GoDashboard } from "react-icons/go";
import { BiLogOut } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import { MdManageAccounts } from "react-icons/md";
import RoleGuard from "@/shared/RoleGuard";
import { KeyIcon } from "@heroicons/react/24/outline";

type Props = {
    qtdCart: any
}

const NavbarSite: FC<Props> = ({ qtdCart }: Props) => {
    const router = useRouter()
    const { data: session } = useSession()

    let Links = [
        { name: '', link: '/' },
    ]
    let [open, setOpen] = useState(false)

    const abrirAdmin = () => {
        router.push('/dashboard')
    }

    const linkMeusPedidos = () => {
        return (
            <Link
                key={`pedido-btn`}
                href="/dashboard/pedidos"
                className="item-menu-home"
            >
                <GoDashboard className="h-4 w-4 mr-2" />
                Meus Ingressos
            </Link>
        )
    }

    const gerenciador = () => {
        return (session?.user.empresas ?? 0) > 0
            ? (
                <Link
                    key={`promotor-btn`}
                    href="/gerenciador"
                    className="item-menu-home"
                >
                    <CogIcon className="h-4 w-4 mr-2" />
                    Gerenciador
                </Link>
            ) : (
                <div></div>
            )
    }

    const linkMeuPerfil = () => {
        return (
            <Link
                key={`perfil-btn`}
                href="/dashboard/perfil"
                className="item-menu-home"
            >
                <UserIcon className="h-4 w-4 mr-2" />
                Meu Perfil
            </Link>
        )
    }

    const linkAdmin = () => {
        return RoleGuard.canRole('administrador', session?.user.roles)
            ? (
                <Link
                    key={`admin-btn`}
                    href="/admin"
                    className="item-menu-home"
                >
                    <KeyIcon className="h-4 w-4 mr-2" />
                    Administrativo
                </Link>
            ) : (
                <div></div>
            )
    }
    

    const sair = () => {
        utils.deleleCartCookie()
        utils.deleleQtdCart()
        signOut()
    }

    const linkSair = () => {
        return (
            <div
                key={`sair`}
                onClick={sair}
                className="item-menu-home"
            >
                <IoMdLogOut className="h-4 w-4 mr-2" />
                Sair
            </div>
        )
    }

    const linkMeusCupons = () => {
        return RoleGuard.canRole('influencer', session?.user.roles ?? [])
            ? (
                <Link
                    key={`pedido-btn`}
                    href="/dashboard/cupons"
                    className="item-menu-home"
                >
                    <RiCoupon3Fill className="h-4 w-4 mr-2" />
                    Meus Cupons
                </Link>
            ) : <div></div>
    }

    return (
        <>
            <div className="w-full flex justify-between container m-auto relative  top-0 left-0">
                <div className=' w-full' style={{ zIndex: 10 }}>
                    <div className='md:flex items-center justify-between md:bg-transparent bg-white w-full  py-4 md:px-10 px-7'>
                        <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
                            <span className='text-3xl text-indigo-600 mr-1 pt-2'>
                                <Link href={'/'}>
                                    <Image src="/images/logo-quebrado.png" alt='' width={130} height={100} />
                                </Link>
                            </span>
                        </div>

                        <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
                            {
                                open && (
                                    <><GrClose /></>
                                )
                            } {
                                !open && (
                                    <><RiMenu2Line /></>
                                )
                            }
                        </div>
                    </div>
                </div>
                <ul className={`md:flex md:items-center justify-end md:pb-0 gap-1 pb-12 absolute md:static bg-white md:shadow-none shadow-lg md:z-auto left-0 w-full md:w-full md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-16 ' : 'top-[-490px]'}`} style={{ zIndex: 9 }}>
                    {
                        Links.map((link) => (
                            <li key={`li${link.name}`} className='md:ml-8 text-xl md:my-0 my-7'>
                                <Link key={`link-${link.name}`} href={link.link} className='text-gray-800 hover:text-gray-400 duration-500'>{link.name}</Link>
                            </li>
                        ))
                    }
                    <div className='flex'>
                        <Button onClick={() => { router.push('/checkout/cart') }} className='border-none' color="light">
                            <FaShoppingCart className='text-myred text-xl mt-2' />
                            <Badge color="failure" className="text-myred" style={{ marginTop: '-15px', marginLeft: '-1px' }}>{qtdCart}</Badge>
                        </Button>
                    </div>


                    {!session && (
                        <>
                            <div onClick={() => abrirAdmin()} className="flex gap-1 items-center justify-center px-3 py-1 border-2 border-red-600 hover:border-red-700 rounded text-red-600 hover:text-red-700 hover:bg-slate-100 cursor-pointer md:mr-0 mr-7">
                                ENTRAR
                            </div>
                            <div onClick={() => router.push('/auth/register')} className="flex gap-1 items-center justify-center px-3 py-1 border-2 bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 rounded text-white hover:bg-slate-100 cursor-pointer md:mr-0 mr-7 mt-3 md:mt-0">
                                CADASTRAR
                            </div>
                        </>
                    )}
                    {session && (
                        <div className="mr-10">
                            <div className="relative text-left md:hidden block">
                                {linkMeusPedidos()}
                                {linkMeuPerfil()}
                                {linkAdmin()}
                                {gerenciador()}
                                {linkSair()}
                            </div>
                            <Menu as="div" className="relative text-left md:block hidden ">
                                <div>
                                    <Menu.Button className="inline-flex w-full justify-center items-center">
                                        {/* <picture>
                    <img
                      src="https://randomuser.me/api/portraits/men/35.jpg"
                      className="rounded-full h-8 md:mr-4 border-2 border-white shadow-sm"
                      alt="profile picture"
                    />
                  </picture> */}
                                        <span className="hidden md:block font-medium text-gray-700">
                                            {/* {utils.primeiroNome(session?.user?.name)} */}
                                            MINHA CONTA
                                        </span>
                                        <ChevronDownIcon className="ml-2 h-4 w-4 text-gray-700" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform scale-95"
                                    enterTo="transform scale-100"
                                    leave="transition ease-in duration=75"
                                    leaveFrom="transform scale-100"
                                    leaveTo="transform scale-95"
                                >
                                    <Menu.Items className="absolute shadow-lg right-0 w-56 z-50 mt-2 origin-top-right bg-white rounded">
                                        <div className="p-1">
                                            <Menu.Item>
                                                {linkMeusPedidos()}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {linkMeuPerfil()}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {linkAdmin()}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {gerenciador()}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {linkSair()}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    )}
                </ul>
            </div>
        </>
    )
}

export default NavbarSite