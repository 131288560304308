import utils from "@/shared/utils";
import { FC, Fragment, useEffect, useState } from "react";
import FooterHome from "../FooterHome";
import NavbarSite from "./NavbarSite";
import AjudaBotao from "../AjudaBotao";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

interface Props {
    title?: string;
    children?: React.ReactNode;
    qtdCart?: any,
    marginBotton?: number
}

export const LayoutHome: FC<Props> = ({ children, marginBotton, qtdCart }: Props) => {
    const [cart, setQtdCart] = useState<any>(qtdCart)
    const router = useRouter()

    useEffect(() => {
        setQtdCart(qtdCart)            
    })

    return (
        <div>
            <NavbarSite qtdCart={cart} />
            {children}
            
            {!router.asPath.includes("/evento/") && (
                <>
                    <AjudaBotao numero=""/>
                </>
            )}

            <FooterHome marginBotton={marginBotton}/>
        </div>
    )
}