import { BsWhatsapp } from "react-icons/bs"

type Props = {
    numero: string
}

export default function AjudaBotao({ numero }: Props) {

    var num = numero != '' ? numero : '69992567130'

    return (
        <>
            <div
                onClick={() => { window.open(`https://wa.me/55${num}`, '_blank') }}
                className="float-button flex justify-center items-center gap-3" style={{zIndex: '49'}}>
                <span className="lg:block hidden">Precisa de ajuda? </span><BsWhatsapp style={{ fontSize: 20 }} />
            </div>
        </>
    )
}